import {FaRegHospital} from 'react-icons/fa';
import {RiCustomerService2Line} from 'react-icons/ri';
import {
  // BsCart4,
  BsHouseDoor,
} from 'react-icons/bs';
import { MdOutlineAnalytics} from 'react-icons/md';
import {ImFeed, ImLab} from 'react-icons/im';
// import {
//   //GrDatabase,
//   GrNavigate
// } from 'react-icons/gr';
import {
  // VscTable,
  VscTools
} from 'react-icons/vsc';
import {AiOutlineLayout} from 'react-icons/ai';

const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'crypto',
        title: 'Home',
        messageId: 'Home',
        type: 'item',
        icon: <BsHouseDoor />,
        url: '/dashboards/crypto',
      },
      {
        id: 'crypto',
        title: 'Task',
        messageId: 'Task',
        type: 'item',
        icon: <RiCustomerService2Line />,
        url: '/dashboards/crm',
      },
      // {
      //   id: 'analytics',
      //   title: 'Fab',
      //   messageId: 'Fab',
      //   type: 'item',
      //   icon: <MdOutlineAnalytics />,
      //   url: '/dashboards/analytics',
      // },
      // {
      //   id: 'healthCare',
      //   title: 'Settings',
      //   messageId: 'Settings',
      //   type: 'item',
      //   icon: <FaRegHospital />,
      //   url: '/dashboards/health-care',
      // },
      // {
      //   id: 'e-commerce',
      //   title: 'Administration',
      //   messageId: 'Administration',
      //   type: 'item',
      //   icon: <BsCart4 />,
      //   url: '/dashboards/e-commerce',
      // },
  
    ],
  },
  {
    id: 'mui',
    title: 'MUI Components',
    messageId: 'sidebar.mui',
    type: 'group',
    children: [
      {
        id: 'inputs',
        title: 'Inputs',
        messageId: 'sidebar.mui.inputs',
        type: 'collapse',
        icon: 'input',
        children: [
          {
            id: 'autocomplete',
            title: 'Autocomplete',
            messageId: 'sidebar.mui.inputs.autocomplete',
            type: 'item',
            url: '/mui/inputs/autocomplete',
          },
          {
            id: 'buttons',
            title: 'Buttons',
            messageId: 'sidebar.mui.inputs.buttons',
            type: 'item',
            url: '/mui/inputs/buttons',
          },
          {
            id: 'button-group',
            title: 'Button Group',
            messageId: 'sidebar.mui.inputs.buttonGroup',
            type: 'item',
            url: '/mui/inputs/button-group',
          },
          {
            id: 'checkboxes',
            title: 'Checkboxes',
            messageId: 'sidebar.mui.inputs.checkboxes',
            type: 'item',
            url: '/mui/inputs/checkboxes',
          },
          {
            id: 'fab',
            title: 'Fab',
            messageId: 'sidebar.mui.inputs.fab',
            type: 'item',
            url: '/mui/inputs/floating-action-button',
          },
          {
            id: 'radios',
            title: 'Radio Button',
            messageId: 'sidebar.mui.inputs.radio',
            type: 'item',
            url: '/mui/inputs/radios',
          },
          {
            id: 'rating',
            title: 'Rating',
            messageId: 'sidebar.mui.util.rating',
            type: 'item',
            url: '/mui/inputs/rating',
          },
          {
            id: 'selects',
            title: 'Selects',
            messageId: 'sidebar.mui.inputs.selects',
            type: 'item',
            url: '/mui/inputs/selects',
          },
          // {
          //   id: 'slider',
          //   title: 'Slider',
          //   messageId: 'sidebar.mui.inputs.slider',
          //   type: 'item',
          //   url: '/mui/inputs/slider',
          // },
          {
            id: 'switches',
            title: 'Switches',
            messageId: 'sidebar.mui.inputs.switches',
            type: 'item',
            url: '/mui/inputs/switches',
          },
          {
            id: 'textField',
            title: 'Text Field',
            messageId: 'sidebar.mui.inputs.textField',
            type: 'item',
            url: '/mui/inputs/text-fields',
          },
          {
            id: 'transfer',
            title: 'Transfer List',
            messageId: 'sidebar.mui.inputs.transfer',
            type: 'item',
            url: '/mui/inputs/transfer-list',
          },
          {
            id: 'toggle-buttons',
            title: 'Toggle Buttons',
            messageId: 'sidebar.mui.util.toggleButtons',
            type: 'item',
            url: '/mui/inputs/toggle-buttons',
          },
        ],
      },
      {
        id: 'dataDisplay',
        title: 'Data Display',
        messageId: 'sidebar.mui.dataDisplay',
        type: 'collapse',
        icon: <FaRegHospital />,
        children: [
          {
            id: 'avatars',
            title: 'Avatars',
            messageId: 'sidebar.mui.dataDisplay.avatars',
            type: 'item',
            url: '/mui/data-display/avatars',
          },
          // {
          //   id: 'badges',
          //   title: 'Badges',
          //   messageId: 'sidebar.mui.dataDisplay.badges',
          //   type: 'item',
          //   url: '/mui/data-display/badges',
          // },
          {
            id: 'chips',
            title: 'Chips',
            messageId: 'sidebar.mui.dataDisplay.chips',
            type: 'item',
            url: '/mui/data-display/chips',
          },
          {
            id: 'divider',
            title: 'Divider',
            messageId: 'sidebar.mui.dataDisplay.divider',
            type: 'item',
            url: '/mui/data-display/divider',
          },
          {
            id: 'lists',
            title: 'Lists',
            messageId: 'sidebar.mui.dataDisplay.lists',
            type: 'item',
            url: '/mui/data-display/lists',
          },
          {
            id: 'tables',
            title: 'Tables',
            messageId: 'sidebar.mui.dataDisplay.tables',
            type: 'item',
            url: '/mui/data-display/tables',
          },
          {
            id: 'tooltip',
            title: 'Tooltip',
            messageId: 'sidebar.mui.dataDisplay.tooltip',
            type: 'item',
            url: '/mui/data-display/tooltip',
          },
          {
            id: 'typography',
            title: 'Typography',
            messageId: 'sidebar.mui.dataDisplay.typography',
            type: 'item',
            url: '/mui/data-display/typography',
          },
        ],
      },
      {
        id: 'navigation',
        title: 'Navigation',
        messageId: 'sidebar.mui.navigation',
        type: 'collapse',
        icon: <MdOutlineAnalytics />,
        children: [
          {
            id: 'bottomNavigation',
            title: 'Bottom Navigation',
            messageId: 'sidebar.mui.navigation.bottom',
            type: 'item',
            url: '/mui/navigation/bottom-navigation',
          },
          {
            id: 'breadcrumbs',
            title: 'Breadcrumbs',
            messageId: 'sidebar.mui.navigation.breadcrumbs',
            type: 'item',
            url: '/mui/navigation/breadcrumbs',
          },
          {
            id: 'drawers',
            title: 'Drawers',
            messageId: 'sidebar.mui.navigation.drawers',
            type: 'item',
            url: '/mui/navigation/drawers',
          },
          {
            id: 'links',
            title: 'Links',
            messageId: 'sidebar.mui.navigation.links',
            type: 'item',
            url: '/mui/navigation/links',
          },
          {
            id: 'menus',
            title: 'Menus',
            messageId: 'sidebar.mui.navigation.menus',
            type: 'item',
            url: '/mui/navigation/menus',
          },
          // {
          //   id: 'pagination',
          //   title: 'Pagination',
          //   messageId: 'sidebar.mui.navigation.pagination',
          //   type: 'item',
          //   url: '/mui/navigation/pagination',
          // },
          {
            id: 'speed-dial',
            title: 'Speed Dial',
            messageId: 'sidebar.mui.navigation.speedDial',
            type: 'item',
            url: '/mui/navigation/speed-dial',
          },
          {
            id: 'steppers',
            title: 'Steppers',
            messageId: 'sidebar.mui.navigation.steppers',
            type: 'item',
            url: '/mui/navigation/steppers',
          },
          {
            id: 'tabs',
            title: 'Tabs',
            messageId: 'sidebar.mui.navigation.tabs',
            type: 'item',
            url: '/mui/navigation/tabs',
          },
        ],
      },
      {
        id: 'surface',
        title: 'Surface',
        messageId: 'sidebar.mui.surface',
        type: 'collapse',
        icon: 'surround_sound',
        children: [
          {
            id: 'appBar',
            title: 'App Bar',
            messageId: 'sidebar.mui.surface.appBar',
            type: 'item',
            url: '/mui/surface/appbar',
          },
          {
            id: 'accordion',
            title: 'Accordion',
            messageId: 'sidebar.mui.surface.accordion',
            type: 'item',
            url: '/mui/surface/accordion',
          },
          {
            id: 'cards',
            title: 'Cards',
            messageId: 'sidebar.mui.surface.cards',
            type: 'item',
            url: '/mui/surface/cards',
          },
          {
            id: 'paper',
            title: 'Paper',
            messageId: 'sidebar.mui.surface.paper',
            type: 'item',
            url: '/mui/surface/paper',
          },
        ],
      },
      {
        id: 'feedback',
        title: 'feedback',
        messageId: 'sidebar.mui.feedback',
        type: 'collapse',
        icon: <ImFeed />,
        children: [
          {
            id: 'alert',
            title: 'Alert',
            messageId: 'sidebar.mui.feedback.alert',
            type: 'item',
            url: '/mui/feedback/alert',
          },
          {
            id: 'backdrop',
            title: 'Backdrop',
            messageId: 'sidebar.mui.feedback.backdrop',
            type: 'item',
            url: '/mui/feedback/backdrop',
          },
          {
            id: 'dialog',
            title: 'Dialog',
            messageId: 'sidebar.mui.feedback.dialog',
            type: 'item',
            url: '/mui/feedback/dialog',
          },
          {
            id: 'progress',
            title: 'Progress',
            messageId: 'sidebar.mui.feedback.progress',
            type: 'item',
            url: '/mui/feedback/progress',
          },
          {
            id: 'skeleton',
            title: 'Skeleton',
            messageId: 'sidebar.mui.feedback.skeleton',
            type: 'item',
            url: '/mui/feedback/skeleton',
          },
          {
            id: 'snackbars',
            title: 'Snackbars',
            messageId: 'sidebar.mui.feedback.snackbars',
            type: 'item',
            url: '/mui/feedback/snackbars',
          },
        ],
      },
      {
        id: 'layout',
        title: 'Layout',
        messageId: 'sidebar.mui.layout',
        type: 'collapse',
        icon: <AiOutlineLayout />,
        children: [
          {
            id: 'box',
            title: 'Box',
            messageId: 'sidebar.mui.layout.box',
            type: 'item',
            url: '/mui/layout/box',
          },
          {
            id: 'container',
            title: 'Container',
            messageId: 'sidebar.mui.layout.container',
            type: 'item',
            url: '/mui/layout/container',
          },
          {
            id: 'grid',
            title: 'Grid',
            messageId: 'sidebar.mui.layout.grid',
            type: 'item',
            url: '/mui/layout/grid',
          },
          {
            id: 'stack',
            title: 'Stack',
            messageId: 'sidebar.mui.layout.stack',
            type: 'item',
            url: '/mui/layout/stack',
          },
          {
            id: 'image-list',
            title: 'Image List',
            messageId: 'sidebar.mui.layout.imageList',
            type: 'item',
            url: '/mui/layout/image-list',
          },
        ],
      },
      {
        id: 'util',
        title: 'Util',
        messageId: 'sidebar.mui.util',
        type: 'collapse',
        icon: <VscTools />,
        children: [
          {
            id: 'click-away-listener',
            title: 'Click away listener',
            messageId: 'sidebar.mui.util.clickAwayListener',
            type: 'item',
            url: '/mui/utility/click-away-listener',
          },
          
          // {
          //   id: 'modal',
          //   title: 'Modal',
          //   messageId: 'sidebar.mui.util.modal',
          //   type: 'item',
          //   url: '/mui/utility/modal',
          // },
          {
            id: 'popover',
            title: 'Popover',
            messageId: 'sidebar.mui.util.popover',
            type: 'item',
            url: '/mui/utility/popover',
          },
          {
            id: 'nossr1',
            title: 'NO SSR1',
            messageId: 'sidebar.mui.util.nossr1',
            type: 'item',
            url: '/mui/utility/nossr1',
          },
          {
            id: 'popper',
            title: 'Popper',
            messageId: 'sidebar.mui.util.popper',
            type: 'item',
            url: '/mui/utility/popper',
          },
          {
            id: 'portal',
            title: 'Portal',
            messageId: 'sidebar.mui.util.portal',
            type: 'item',
            url: '/mui/utility/portal',
          },
          {
            id: 'textarea-autosize',
            title: 'Textarea Autosize',
            messageId: 'sidebar.mui.util.textareaAutosize',
            type: 'item',
            url: '/mui/utility/textarea-autosize',
          },
          {
            id: 'transitions',
            title: 'Transitions',
            messageId: 'sidebar.mui.util.transitions',
            type: 'item',
            url: '/mui/utility/transitions',
          },
          {
            id: 'mediaquery',
            title: 'Media query',
            messageId: 'sidebar.mui.util.mediaquery',
            type: 'item',
            url: '/mui/utility/media-query',
          },
        ],
      },
     
      {
        id: 'lab',
        title: 'Lab',
        messageId: 'sidebar.mui.lab',
        type: 'collapse',
        icon: <ImLab />,
        children: [
          {
            id: 'date-time',
            title: 'Date Time',
            messageId: 'sidebar.mui.lab.dateTime',
            type: 'item',
            url: '/mui/lab/date-picker',
          },
          {
            id: 'date-range-picker',
            title: 'Date Range Picker',
            messageId: 'sidebar.mui.lab.dateRangePicker',
            type: 'item',
            url: '/mui/lab/date-range-picker',
          },
          {
            id: 'date-time-picker',
            title: 'Date Time Picker',
            messageId: 'sidebar.mui.lab.dateTimePicker',
            type: 'item',
            url: '/mui/lab/date-time-picker',
          },
          {
            id: 'time-picker',
            title: 'Time Picker',
            messageId: 'sidebar.mui.lab.timePicker',
            type: 'item',
            url: '/mui/lab/time-picker',
          },
          {
            id: 'masonry',
            title: 'Masonry',
            messageId: 'sidebar.mui.lab.masonry',
            type: 'item',
            url: '/mui/lab/masonry',
          },
          {
            id: 'timeline',
            title: 'Time Line',
            messageId: 'sidebar.mui.lab.timeline',
            type: 'item',
            url: '/mui/lab/timeline',
          },
          {
            id: 'trap-focus',
            title: 'Trap Focus',
            messageId: 'sidebar.mui.lab.trapFocus',
            type: 'item',
            url: '/mui/lab/trap-focus',
          },
          {
            id: 'tree-view',
            title: 'Tree View',
            messageId: 'sidebar.mui.lab.treeView',
            type: 'item',
            url: '/mui/lab/tree-view',
          },
        ],
      },
    ],
  },
 
];
export default routesConfig;
