import React from 'react';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const DatePicker = React.lazy(() => import('./DatePicker'));
const DateRangePicker = React.lazy(() => import('./DateRangePicker'));
const DateTimePicker = React.lazy(() => import('./DateTimePicker'));
const TimePicker = React.lazy(() => import('./TimePicker'));

export const dateConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/date-picker',
    element: <DatePicker />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/date-range-picker',
    element: <DateRangePicker />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/date-time-picker',
    element: <DateTimePicker />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/time-picker',
    element: <TimePicker />,
  },
];
