import {dateConfigs} from './dateTime';
import {RoutePermittedRole} from '../../../shared/constants/AppConst';
import React from 'react';

const Masonry = React.lazy(() => import('./Masonry'));
const Timeline = React.lazy(() => import('./Timeline'));
const TrapFocus = React.lazy(() => import('./TrapFocus'));
const TreeView = React.lazy(() => import('./TreeView'));

export const labConfigs = [
  ...dateConfigs,

  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/masonry',
    element: <Masonry />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/timeline',
    element: <Timeline />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/trap-focus',
    element: <TrapFocus />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/mui/lab/tree-view',
    element: <TreeView />,
  },
];
