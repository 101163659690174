import React, {useState} from 'react';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
// import AppNotificationContent from './AppNotificationContent';
// import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AppTooltip from '../AppTooltip';
import {alpha} from '@mui/material/styles';
import {FaRegUser} from 'react-icons/fa';
import MenuItem from '@mui/material/MenuItem';
import {useAuthMethod} from '@crema/utility/AuthHooks';
import {useNavigate} from 'react-router-dom';
import Menu from '@mui/material/Menu';

const AppUser = ({
  tooltipPosition,
  isMenu,
  // sxNotificationContentStyle,
}) => {
  const [setShowNotification] = useState(false);
  const {logout} = useAuthMethod();
  //const {user} = useAuthUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

   const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
   };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <AppTooltip title='User' placement={tooltipPosition}>
          <IconButton
            className='icon-btn'
            sx={{
              borderRadius: '80%',
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: 'transparent',
              '&:hover, &:focus': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
              }}
              onClick={handleClick}
            // onClick={() => onClick={handleClick}}
            size='large'
          >
            <FaRegUser />
          </IconButton>
        </AppTooltip>
      )}

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate('/my-profile');
          }}
        >
          My account
        </MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AppUser;

AppUser.defaultProps = {
  drawerPosition: 'right',
  tooltipPosition: 'bottom',
};

AppUser.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
